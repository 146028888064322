import * as Yup from 'yup';
// add validation to driver form
const phoneRegExp = /^[6-9]\d{9}$/;

const userFormSchema = Yup.object().shape({
    referral: Yup.string().required('Referral is required.'),
    higher_status: Yup.string().required('Higher Status is required.'),
    status: Yup.string().required('Status is required.'),
})

export default userFormSchema;
