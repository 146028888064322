import * as Yup from 'yup';
// add validation to driver form
const storeFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(255),
    higher_store_id: Yup.number().required('Store ID is required').typeError('Store ID must be number.'),
    lower_store_id: Yup.number().required('Store ID is required').typeError('Store ID must be number.'),
    store_url: Yup.string().required('Store URL is required').max(255),
    // store_key: Yup.string().required('Store Key is required').max(255),
    higher_url_add: Yup.string().max(255),
    lower_url_add: Yup.string().max(255),
    higher_url_remove: Yup.string().max(255),
    lower_url_remove: Yup.string().max(255),
    higher_sub_id_keys: Yup.string().required('Sub ID Keys is required').max(255),
    lower_sub_id_keys: Yup.string().required('Sub ID Keys is required').max(255),
    status: Yup.string().required('Status is required').max(255),
});


export default storeFormSchema;
