import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { SidebarData } from './sidebarData';
import SubMenu from './subMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LogOut } from 'react-feather';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor:' #FFFFFF',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor:' #FFFFFF',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  // const login_id = localStorage.getItem("bd_bki");
  const login_type = localStorage.getItem("bd_bka");
  const user_name = localStorage.getItem("bd_bkn");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const text_color = { width: '100px', marginTop:'10px', fontSize:'18px', background: '-webkit-linear-gradient(270deg, #FFED8B 0%, #FFE77B 15.89%, #FBBC05 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' };
  const text_color = { width: '100px', marginTop:'10px', fontSize:'18px', color: '#FFFFFF' };

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: '#2F3C97', }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
              color:'#FFFFFF'
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={6} sm={6} md={6}>
              {open === false ? <Typography  sx={text_color} >BD&nbsp;Converter</Typography> : ''}
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              
              <IconButton onClick={handleClick}  sx={{ color: '#FFFFFF', float: 'right' }} >
                <span style={{fontSize:'15px', marginRight:'5px'}}>{user_name}</span>  <AccountCircleIcon sx={{ color: '#FFFFFF' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} >
        <DrawerHeader sx={{ backgroundColor: '#2F3C97' }} >
          <Stack direction="row" spacing={8}>
          <Typography  sx={text_color} >BD&nbsp;Converter</Typography>
            <IconButton onClick={handleDrawerClose} >
              {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: '#FFFFFF' }} /> : <KeyboardDoubleArrowLeftIcon sx={{ color: '#FFFFFF' }} />}
            </IconButton>
          </Stack>
        </DrawerHeader>
        {SidebarData.map((item, index) => {
          // if(login_type === 'Admin' && (item.path === '/agents' || item.path === '/nse')) {
          //   return null;
          // }else if(login_type === 'Supervisor' && (item.path === '/supervisors' || item.path === '/agents')) {
          //   return null;
          // }else if(login_type !== 'Supervisor' && item.path === '/requests' ) {
          //   return null;
          // }else if(login_type === 'Nse' && (item.path === '/supervisors' || item.path === '/nse')) {
          //   return null;
          // } else {
            return <SubMenu item={item} key={index} open={open} />;
          // }
        })}

      </Drawer>
      
    </Box>
    <Menu
    id="demo-positioned-menu"
    aria-labelledby="demo-positioned-button"
    anchorEl={anchorEl}
    open={openMenu}
    onClose={handleClose}


  >

    {/* <MenuItem >
      <ListItemIcon>
        <User fontSize="small" />
      </ListItemIcon>
      Profile
    </MenuItem> */}
    <MenuItem >
      <ListItemIcon >
        <LogOut fontSize="small" />
      </ListItemIcon>
      <Link to="/logout" style={{textDecoration :'none', color:'#000000'}}>
      <ListItemText >Logout</ListItemText></Link>
    </MenuItem> 
  </Menu></>
  );
}
