import React, { useRef } from 'react';
import { useState, } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UserSchema from '../../validations/userSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import Alert from '../../components/alert';
import { Link } from 'react-router-dom';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function Create() {
    const dialogBox = useRef()
    const [apiErrors, setApiErrors] = useState([]);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('');

    const bundle = localStorage.getItem("bd_aat");
    const login_id = localStorage.getItem("bd_bki");
    // const login_type = localStorage.getItem("bka");

    const formik = useFormik({
        initialValues: {
            referral: '',
            higher_status: '',
            status: '',
        },
        enableReinitialize: true,
        validationSchema: UserSchema,

        onSubmit: (initialValues, { resetForm }) => {
            console.log(initialValues);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/users/add`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log("data : ", data)
                    if ('error' in data) {
                        resetForm();
                        setApiErrors(data.errors);
                    } else if ('success' in data) {
                        resetForm();
                        setApiErrors([])
                        setAlert(true);
                        setType('success');
                        setMessage(data.message);
                    } else {
                        resetForm();
                        setApiErrors([])
                        setAlert(true);
                        setType('error');
                        setMessage('Something went wrong');
                    }
                });
            // navigate('/bank-info', { state: { path: 'login' } });

        }
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormikProvider value={formik}  >
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                        <Box sx={{display:'flex', justifyContent:'space-between'}} mb={3}>
                            <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add User</Typography>
                            <Link to="/logout" ><Button variant="contained"  type="button" color="primary" sx={{ textTransform: 'capitalize', margin:'5px' }}>  Logout</Button></Link>
                        </Box>

                        <Grid container spacing={3}>
                            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color: '#FFFFFF' }}>
                                    {
                                        Object.keys(apiErrors).map((item, i) => (
                                            <Typography key={i}>{apiErrors[item]}</Typography>
                                        ))
                                    }
                                </Box>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Referral Code"
                                    {...getFieldProps('referral')}
                                    size="small"
                                    error={Boolean(touched.referral && errors.referral)}
                                    helperText={touched.referral && errors.referral}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Status"
                                        {...getFieldProps('status')}
                                        error={Boolean(touched.status && errors.status)}
                                    >
                                        <MenuItem value="1">Active</MenuItem>
                                        <MenuItem value="0">Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Select Higher Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Select Higher Status"
                                        {...getFieldProps('higher_status')}
                                        error={Boolean(touched.higher_status && errors.higher_status)}
                                    >
                                        <MenuItem value="1">Active</MenuItem>
                                        <MenuItem value="0">Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button variant="contained" size="large" type="submit" color="secondary" sx={{ width: '100%', textTransform: 'capitalize' }}>Save</Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Grid>
            <Alert open={alert} type={type} message={message} setOpen={setAlert} />
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
        </Grid>
    )
}