import * as Yup from 'yup';
// add validation to driver form
const phoneRegExp = /^[6-9]\d{9}$/;

const networkFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(255),
    domain_name: Yup.string().required('Domain Name is required'),
    deeplink_key: Yup.string().required('Deeplink identifier is required.'),
    // exit_id_1: Yup.string().required('Exit id 1 is required.'),
    // exit_id_2: Yup.string().required('Exit id 2 is required.'),
})

export default networkFormSchema;
