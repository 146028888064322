import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import StoreSchema from '../../validations/storeSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import Alert from '../../components/alert';

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Edit(props) {
    const navigate = useNavigate();

    const location = useLocation();

    const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("bd_aat");
    // const login_type = localStorage.getItem("bka");
    // const [preview, setPreview] = useState();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            name: '',
            store_url: '',
            higher_secure_tracker_key: '',
            higher_url_add: '',
            higher_url_remove: '',
            higher_sub_id_keys: '',
            higher_store_id: '',
            lower_secure_tracker_key: '',
            lower_url_add: '',
            lower_url_remove: '',
            lower_sub_id_keys: '',
            lower_store_id: '',
            store_key: '',
            status: true,
            without_network: false,
        },
        enableReinitialize: true,
        validationSchema: StoreSchema,

        onSubmit: async (initialValues, { resetForm }) => {

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/stores`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        resetForm();
                        setApiErrors(data.errors);
                    } else if ('success' in data) {
                        resetForm();
                        setAlert(true);
                        setType('success');
                        setMessage(data.message);
                        setTimeout(
                            function () {
                                navigate('/stores');
                            }
                                .bind(this),
                            1000
                        );
                    } else {
                        resetForm();
                        setAlert(true);
                        setType('error');
                        setMessage('Something went wrong');
                    }
                });


        }

    });

    const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <Box >
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
                    <Link
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="#1B191F"
                        href="/"
                        key="1"
                    >
                        <HomeIcon sx={{ mr: 0.5 }} /> Dashboard
                    </Link>
                    <Link underline="hover" color="#1B191F" href="/stores" key="2" >Stores</Link>
                    <Link underline="none" color="gray" key="3" >Create</Link>
                </Breadcrumbs>
                <FormikProvider value={formik} >
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '20px', border: '1px solid #1B191F', borderRadius: '5px' }}>
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Add Store</Typography>
                        <Grid container spacing={3} >
                            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color: '#FFFFFF' }}>
                                    {
                                        Object.keys(apiErrors).map((item, i) => (
                                            <Typography key={i}>{apiErrors[item]}</Typography>
                                        ))
                                    }
                                </Box>
                            </Grid>}

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Name"
                                    {...getFieldProps('name')}
                                    size="small"
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Store URL"
                                    {...getFieldProps('store_url')}
                                    size="small"
                                    error={Boolean(touched.store_url && errors.store_url)}
                                    helperText={touched.store_url && errors.store_url}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Higher Secure Tracker Key"
                                            {...getFieldProps('higher_secure_tracker_key')}
                                            size="small"
                                            error={Boolean(touched.higher_secure_tracker_key && errors.higher_secure_tracker_key)}
                                            helperText={touched.higher_secure_tracker_key && errors.higher_secure_tracker_key ? touched.higher_secure_tracker_key && errors.higher_secure_tracker_key : <b>If tracking is https://secure.traqkarr.com. Add a key of redirection.</b>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Higher Url Add"
                                            {...getFieldProps('higher_url_add')}
                                            size="small"
                                            error={Boolean(touched.higher_url_add && errors.higher_url_add)}
                                            helperText={touched.higher_url_add && errors.higher_url_add}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Higher Url Remove"
                                            {...getFieldProps('higher_url_remove')}
                                            size="small"
                                            error={Boolean(touched.higher_url_remove && errors.higher_url_remove)}
                                            helperText={touched.higher_url_remove && errors.higher_url_remove}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="HIgher Sub ID Keys"
                                            {...getFieldProps('higher_sub_id_keys')}
                                            size="small"
                                            error={Boolean(touched.higher_sub_id_keys && errors.higher_sub_id_keys)}
                                            helperText={touched.higher_sub_id_keys && errors.higher_sub_id_keys}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Store ID (Higher Payout)"
                                            {...getFieldProps('higher_store_id')}
                                            size="small"
                                            multiline
                                            error={Boolean(touched.higher_store_id && errors.higher_store_id)}
                                            helperText={touched.higher_store_id && errors.higher_store_id}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                           <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Lower Secure Tracker Key"
                                            {...getFieldProps('lower_secure_tracker_key')}
                                            size="small"
                                            error={Boolean(touched.lower_secure_tracker_key && errors.lower_secure_tracker_key)}
                                            helperText={touched.lower_secure_tracker_key && errors.lower_secure_tracker_key ? touched.lower_secure_tracker_key && errors.lower_secure_tracker_key : <b>If tracking is https://secure.traqkarr.com. Add a key of redirection.</b>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Lower Url Add"
                                            {...getFieldProps('lower_url_add')}
                                            size="small"
                                            error={Boolean(touched.lower_url_add && errors.lower_url_add)}
                                            helperText={touched.lower_url_add && errors.lower_url_add}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Lower Url Remove"
                                            {...getFieldProps('lower_url_remove')}
                                            size="small"
                                            error={Boolean(touched.lower_url_remove && errors.lower_url_remove)}
                                            helperText={touched.lower_url_remove && errors.lower_url_remove}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Lower Sub ID Keys"
                                            {...getFieldProps('lower_sub_id_keys')}
                                            size="small"
                                            error={Boolean(touched.lower_sub_id_keys && errors.lower_sub_id_keys)}
                                            helperText={touched.lower_sub_id_keys && errors.lower_sub_id_keys}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            label="Store ID (Lower Payout)"
                                            {...getFieldProps('lower_store_id')}
                                            size="small"
                                            multiline
                                            error={Boolean(touched.lower_store_id && errors.lower_store_id)}
                                            helperText={touched.lower_store_id && errors.lower_store_id}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    variant="outlined"
                                    label="Store ID"
                                    {...getFieldProps('store_key')}
                                    size="small"
                                    error={Boolean(touched.store_key && errors.store_key)}
                                    helperText={touched.store_key && errors.store_key ? touched.store_key && errors.store_key : <b>A key to append store id in redirection url.</b>}

                                />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={values.status} onChange={(event, checked) => setFieldValue('status', checked ? true : false)} />} label="Status" />
                                </FormGroup>
                                {/* <SwitchButton  {...getFieldProps('status')} checked={values.status} onChange={(event, checked) =>setFieldValue('status',checked ? true : false) } /> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={values.without_network} onChange={(event, checked) => setFieldValue('without_network', checked ? true : false)} />} label="Without Network?" />
                                </FormGroup>
                                {/* <SwitchButton  {...getFieldProps('status')} checked={values.status} onChange={(event, checked) =>setFieldValue('status',checked ? true : false) } /> */}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button variant="contained" type="submit" color="secondary" sx={{ float: 'right', textTransform: 'capitalize', width: '20%' }}>Save</Button>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
            </Box>
            <Alert open={alert} type={type} message={message} setOpen={setAlert} />
        </>
    )
}