import React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Grid, Button } from '@mui/material';

export default function Filter(props) {
    const { setFilters, onFilter} = props;
    const [values, setValues] = useState({
        referral :'',
        mobile :'',
    });
    // const [btnLoad, setbtnLoad] = React.useState(false);
    const changeValue = (e, parm) => {
        setValues(prevState => ({...prevState, [parm]: e.target.value }))
    }
    const filterdata = () => {
        setFilters(values);
        onFilter(true);
    }
    const handleResetClick = () => {
        setValues({
            referral :'',
            mobile :'',
        });
        setFilters({
            referral :'',
            mobile :'',
        });
        onFilter(true);
    }
     
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
                <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Enter referral"
                    autoFocus
                    size="small"
                    value={values.referral}
                    onChange={(e) => changeValue(e, 'referral')}
                />
            </Grid>
            
            <Grid item xs={12} sm={6} md={6} >
                <TextField
                    fullWidth
                    type="tel"
                    variant="outlined"
                    label="Enter Mobile"
                    size="small"
                    value={values.mobile}
                    onChange={(e) => changeValue(e, 'mobile')}
                />
            </Grid>
            
            <Grid item xs={12} md={12} style={{ textAlign: 'right' }}>
                <Button
                    variant="text"
                    sx={{color:'#1B5E28'}}
                    onClick={filterdata}
                >
                    Search
                </Button>
                <Button
                    variant="text"
                    sx={{color:'#DB0011'}}
                    onClick={handleResetClick}
                >
                    Reset
                </Button>
            </Grid>
        </Grid>
    );
}
