import * as React from 'react';
import { useState,useEffect } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Grid, Stack, Box } from '@mui/material';
import FilterPopup from './filter';
import ViewPopup from './view';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function Index() {
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [openView, setOpenView] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [filters, setFilters] = useState({
    store_name :'',
    status :''
  });
  const [onFilter, setOnFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [size] = useState(15);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [totalStores, setTotalStores] = useState(0);
  // const [setAccessToken] = useState();
  // const [isLoading, setLoading] = useState(false);
  const bundle = localStorage.getItem("bd_aat");
  // const bundle = localStorage.getItem('bd_bundle');

  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/stores?store_name=${filters.store_name}&status=${filters.status}&page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.dataItems.length !== undefined) {
            setData(data.dataItems);
            setTotalStores(data.totalItems);
            setTotalItems(data.totalItems % size ? (Math.floor(data.totalItems / size) + 1) : Math.floor(data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
    });
  }
  useEffect(() => {
    callApi();
  }, [page]);// eslint-disable-line react-hooks/exhaustive-deps

  const openViewPopup = (e, id) => {
    setDataID(id);
    setRecord(data.find(o => o.id === id));
    setOpenView(!openView);
  };
  // const openDeletePopup = (e, id) => {
  //   setDataID(id);
  //   setOpenDelete(!openDelete);
  // };
  // console.log(filters);
  if(onFilter === true ){
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e,p) => {
    setPage(p - 1 );
  }

  const handleOpen = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <>
      <Accordion sx={{border: '1px solid gray'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <FilterPopup  setFilters={setFilters}  onFilter={setOnFilters}/>
        </AccordionDetails>
      </Accordion>
      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{paddingY:'10px'}}>Stores</Typography>
        <Link to="/stores/create"><Button variant="contained" startIcon={<AddIcon />}  sx={{ float: 'right', marginY: '10px', backgroundColor: '#1b5e20' }} > Add Store</Button></Link>
      </Stack>
      {isLoading === true ? <Loader  show={isLoading}/> :
      <>
      <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="a dense table" >
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{fontSize:'16px'}}>#</StyledTableCell>
              <StyledTableCell sx={{fontSize:'16px'}}>Name</StyledTableCell>
              <StyledTableCell sx={{fontSize:'16px'}}>Store URL</StyledTableCell>
              <StyledTableCell sx={{fontSize:'16px'}}>Higher Store ID</StyledTableCell>
              <StyledTableCell sx={{fontSize:'16px'}}>Lower Store ID</StyledTableCell>
              <StyledTableCell sx={{fontSize:'16px'}}>Status</StyledTableCell>
              <StyledTableCell sx={{fontSize:'16px'}}>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? data.map((item) => (
              <React.Fragment key={item.id}>
              <TableRow >
                <TableCell sx={{paddingY:'0px'}}>
                  <IconButton
                    size="small"
                    // onClick={() => setOpen(!open)}
                    onClick={() => handleOpen(item.id)}
                  >
                    {open[item.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell sx={{paddingY:'0px'}}>{item.name}</TableCell>
                <TableCell sx={{paddingY:'0px'}}>{item.store_url}</TableCell>
                <TableCell sx={{paddingY:'0px'}}>{item.higher_store_id}</TableCell>
                <TableCell sx={{paddingY:'0px'}}>{item.lower_store_id}</TableCell>
                <TableCell sx={{paddingY:'0px'}}>{item.status ===  true ? <span style={{color:'#00c853'}}>Active</span> : <span style={{color:'#DB0011'}}>Inactive</span>}</TableCell>
                <TableCell sx={{paddingY:'0px'}}>
                  <IconButton sx={{color:'#1a237e'}} >
                    <Link to="/stores/edit" state={{record: item}}><EditIcon /></Link>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                  <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Grid container>
                      
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Higher Sub Id Keys:</span> {item.higher_sub_id_keys}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Typography sx={{fontSize:'0.875rem', }}><span style={{fontWeight:'600'}}>Lower Sub Id keys:</span> {item.lower_sub_id_keys}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Higher URL Add:</span> {item.url_add}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Typography sx={{fontSize:'0.875rem', }}><span style={{fontWeight:'600'}}>Higher URL Remove:</span> {item.higher_url_remove}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Typography sx={{fontSize:'0.875rem'}}><span style={{fontWeight:'600'}}>Lower URL Add:</span> {item.lower_url_add}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                          <Typography sx={{fontSize:'0.875rem', }}><span style={{fontWeight:'600'}}>Lower URL Remove:</span> {item.lower_url_remove}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              </React.Fragment>
            )) : <TableRow>
                  <TableCell component="th" scope="item" sx={{paddingY:'0px'}} colSpan={6}>No Record Found</TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {data.length > 0 ? <Pagination count={totalItems} page={page+1} color="primary" sx={{ paddingY: '20px' }} onChange={(e,page) => handlePagination(e,page)} /> : ''}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{float:'right', paddingTop:'20px', paddingRight:'10px', fontWeight:'600'}}>Total Stores: {totalStores}</Typography></Grid>
      </Grid> </>}
      <ViewPopup openPopup={openView} setOpenPopup={openViewPopup} setMessage={setMessage}  setAlert={setAlert} setType={setType}   data_id={data_id} record={record}/>
      <Alert open={alert} type={type} message={message} setOpen={setAlert}/>

    </>
  );
}
