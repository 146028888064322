import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TextField, Box } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import { Grid, Stack } from '@mui/material';
import CreatePopup from './create';
import EditPopup from './edit';
import FilterPopup from './filter';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import * as XLSX from 'xlsx/xlsx.mjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function Index() {
  const navigate = useNavigate();
  const dialogBox = useRef()
  // const [open, setOpen] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [type, setType] = useState('');
  const [deleteError, setDeleteError] = useState('Please Type "Delete" to confirm');
  const [deleteText, setDeleteText] = useState('');
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    referral: '',
    mobile: '',
  });
  const [onFilter, setOnFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(25);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [message, setMessage] = useState('');
  const [deleteID, setDeleteID] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [importLoad, setImportLoad] = useState(false);
  const login_id = localStorage.getItem("bd_bki");
  const bundle = localStorage.getItem("bd_aat");

  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/networks?page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data.dataItems.length !== undefined) {
            setData(data.dataItems);
            setTotalUsers(data.totalItems);
            setTotalItems(data.totalItems % size ? (Math.floor(data.totalItems / size) + 1) : Math.floor(data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }
  useEffect(() => {
    callApi();
  }, [page, size]);// eslint-disable-line react-hooks/exhaustive-deps

  const openCreatePopup = () => {
    setOpenCreate(!openCreate);
  };
  const openEditPopup = (e, id) => {
    setDataID(id);
    setRecord(data.find(o => o.id === id));
    setOpenEdit(!openEdit);
  };

  if (onFilter === true) {
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e, p) => {
    setPage(p - 1);
  }
  const refreshTable = () => {
    callApi();
  };

  const uploadFile = (e) => {
    setFileImport(e)
    setOpenConfirm(true)
  }
  const handleUpload = () => {
    setOpenConfirm(false)
    handleFile(fileImport)
  }
  const handleFile = (e) => {
    /* Boilerplate to set up FileReader */
    setLoader(true)
    e.preventDefault();
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      // console.log(data)
      let alldata = data
      let error = false;
      let errorMsg = '';
      let data1 = []
      var workbook = XLSX.utils.book_new();
      data.forEach(row => {
        for (let key in row) {
          if (key === 'mobile') {
            let phoneno = new RegExp(/^[6-9]\d{9}$/);
            if (!(phoneno.test(row[key]))) {
              error = true;
              errorMsg = 'Invalid Mobile'
              alldata = alldata.filter(item => item.mobile != row[key])
            }
          }
          if (key === 'email') {
            let emailRegex = new RegExp(/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);
            if (!(emailRegex.test(row[key]))) {
              error = true;
              errorMsg += 'Invalid Email'
              alldata = alldata.filter(item => item.email != row[key])
            }
          }

        }
        // console.log('----------------------*******************');

        if (error) {
          row.message = errorMsg
          row.insertStatus = 'error'
          data1.push(row)
        }
        error = false
        errorMsg = ''
      });

      const json = JSON.stringify(alldata);
      const blob = new Blob([json], {
        type: 'application/json'
      });
      const formdata = new FormData();
      formdata.append("document", blob);
      const requestOptions = {
        method: "POST",
        headers: {
          "Accept": "application/json ,text/plain, */*",
          // "Content-Type": "multipart/form-data",
          // "Content-Type": "application/json",
          'Authorization': 'Bearer ' + bundle
        },
        body: formdata
      };
      fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/networks/import`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setLoader(false)
          e.target.files = null;
          setFileImport(null)
          if ('success' in data) {
            for (let i = 0; i < data.resultArr.length; i++) {
              data1.push(data.resultArr[i])
            }
            var ws1 = XLSX.utils.json_to_sheet(data1);
            XLSX.utils.book_append_sheet(workbook, ws1, "Results");
            XLSX.writeFile(workbook, 'output.xlsx', { type: 'file' });
            setAlert(true);
            setType('info');
            setMessage("File uploaded, Please upload in downloaded file.");
          } else {
            setAlert(true);
            setType('error');
            setMessage(data.message);
            // alert(data.message)
          }
          refreshTable()
        });
    };

    if (rABS) {
      reader.readAsBinaryString(e.target.files[0]);
    } else {
      reader.readAsArrayBuffer(e.target.files[0]);
    };
  }
  const handleSampleClick = () => {
    let data1 = [{
      "name": "abc",
      "mobile": 9988776655,
      "email": "test@gmail.com",
      "referral": "97489",
      "higher_status": '1',
      "status": '0'
    }]
    var workbook = XLSX.utils.book_new();
    var ws1 = XLSX.utils.json_to_sheet(data1);
    XLSX.utils.book_append_sheet(workbook, ws1, "Sample");
    XLSX.writeFile(workbook, 'sample_user.xlsx', { type: 'file' });
  }
  const handleDelete = (id) => {
    setDeleteID(id)
    setOpenDelete(true)
  }
  const handleConfirmDelete = () =>{
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bundle}`
      },
    };
    if(deleteText == 'Delete'){

      fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/networks/${deleteID}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
          refreshTable()
        }
        setDeleteID('')
        setDeleteError('Please Type "Delete" to confirm');
        setDeleteText('');
        setOpenDelete(false)
      });
    }else {
      setDeleteError('Invaild input. Please enter "Delete".')
    }
  }
  return (
    <>
      <Accordion sx={{ border: '1px solid gray' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Filter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterPopup setFilters={setFilters} onFilter={setOnFilters} />
        </AccordionDetails>
      </Accordion>

      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>Networks</Typography>
        <Stack direction="row" alignItems="right" spacing={1} sx={{ marginY: '10px', }}>
          {/* <Stack direction="column">
            <LoadingButton variant="contained" component="label" startIcon={<UploadIcon />} loading={importLoad} sx={{ backgroundColor: '#ff6f00' }} >
              Import
              <input hidden type="file" onChange={e => uploadFile(e)} />
            </LoadingButton>
            <Button variant="text" sx={{ textTransform: 'capitalize' }} onClick={handleSampleClick}>Sample File <DownloadIcon fontSize="small" /></Button>
          </Stack> */}
          {/* <Stack direction="column">
            <LoadingButton variant="contained" component="button" startIcon={<DownloadIcon />} loading={exportLoad} sx={{ backgroundColor: '#1a237e' }} onClick={handleDownloadClick}> Export </LoadingButton>
          </Stack> */}
          <Stack direction="column">
            <Button variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: '#1b5e20' }} onClick={openCreatePopup}> Add </Button>
          </Stack>
        </Stack>
      </Stack>
      {isLoading === true ? <Loader show={isLoading} /> :
        <>
          <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Name</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Domain</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Deeplink Identifier</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Exit ID 1</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Exit ID 2</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? data.map((item) => (
                  <React.Fragment key={item.id}>
                    <TableRow >
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '4px' }}>{item.name}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '4px' }}>{item.domain_name}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '4px' }}>{item.deeplink_key}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '4px' }}>{item.exit_id_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', padding: '4px' }}>{item.exit_id_2}</TableCell>
                      <TableCell sx={{ textAlign:'center', margin: '0px', fontSize: '13px', padding: '0px' }}>
                        <IconButton sx={{ color: '#1a237e' }} onClick={(e) => openEditPopup(e, item.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton sx={{ color: '#d50000' }} onClick={(e) => handleDelete(item.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )) : <TableRow>
                  <TableCell component="th" scope="item" colSpan={6}>No Record Found</TableCell>
                </TableRow>
                }
              </TableBody>

            </Table>
          </TableContainer>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{ paddingTop: '20px', paddingRight: '10px', fontWeight: '600' }}>Total Users: {totalUsers}</Typography></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {data.length > 0 ? <Pagination count={totalItems} page={page + 1} color="primary" sx={{ paddingY: '20px' }} onChange={(e, page) => handlePagination(e, page)} /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormControl sx={{ minWidth: 100, marginTop: '15px', float: 'right' }} size="small">
                <InputLabel id="demo-simple-select-helper-label">Size Per Page</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={size}
                  label="Size Per Page"
                  onChange={(e) => setSize(e.target.value)}
                >
                  <MenuItem value="25">25</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                  <MenuItem value="500">500</MenuItem>
                  <MenuItem value="1000">1000</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> </>}
      <CreatePopup openPopup={openCreate} setOpenPopup={openCreatePopup} setMessage={setMessage} setAlert={setAlert} setType={setType} admin_id={login_id} refreshTable={refreshTable} />
      <EditPopup openPopup={openEdit} setOpenPopup={openEditPopup} setMessage={setMessage} setAlert={setAlert} setType={setType} admin_id={login_id} refreshTable={refreshTable} data_id={data_id} record={record} />
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />
      <Dialog
        ref={dialogBox}
        open={openConfirm}
        onClose={() => { setOpenConfirm(false) }}
      >
        <DialogContent>
          <Typography variant="h6" component="div" sx={{ color: '#07090A', fontWeight: '500' }}>
            Are you sure to upload this file?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenConfirm(false); setFileImport(null) }} sx={{ color: '#DB0011' }}>Decline</Button>
          <Button onClick={handleUpload} sx={{ color: '#1B5E20' }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />
      <Dialog
        ref={dialogBox}
        open={openDelete}
        onClose={() => { setOpenDelete(false) }}
      >
        <DialogContent>
          <Typography variant="h6" component="div" sx={{ color: '#07090A', fontWeight: '500' }}>
            Are you sure to delete this network?
          </Typography>
          <TextField
              fullWidth
              type="text"
              variant="outlined"
              label=""
              size="small"
              onChange= {(e) => setDeleteText(e.target.value)}
          />
          <span style={{color:'#DB0011', fontSize:'14px'}}>{deleteError}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenDelete(false); setFileImport(null) }} sx={{ color: '#DB0011' }}>Cancel</Button>
          <Button onClick={handleConfirmDelete } sx={{ color: '#1B5E20' }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
