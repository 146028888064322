import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box, IconButton } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Alert from './../components/alert'
import CardContent from '@mui/material/CardContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Loader from '../components/loader';
import CopyIcon from '@mui/icons-material/ContentCopy';
import InputAdornment from '@mui/material/InputAdornment';
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Edit(props) {
    const navigate = useNavigate();

    const location = useLocation();

    const [apiErrors, setApiErrors] = useState([]);
    const [url, setUrl] = useState('');
    const [isLoading, setLoader] = useState(false);
    const bundle = '';
    let refer = localStorage.getItem("bd_ref");
    // const login_type = localStorage.getItem("bka");
    // const [preview, setPreview] = useState();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const ConverterSchema = Yup.object().shape({
        deal: Yup.string().required('Link is required'),
        referral: Yup.string().required('Referral is required'),
    });
    const formik = useFormik({
        initialValues: {
            deal: '',
            referral: refer ? refer : '',
        },
        enableReinitialize: true,
        validationSchema: ConverterSchema,

        onSubmit: async (initialValues, { resetForm, setErrors }) => {
            setLoader(true);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };
            fetch(`${process.env.REACT_APP_SITE_URL}/api/link/get-post`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        setAlert(true);
                        setType('error');
                        setMessage(data.message);
                    }else if ('success' in data) {
                        resetForm();
                        localStorage.setItem("bd_ref", initialValues?.referral);
                        setUrl(data?.data)
                    } else {
                        resetForm();
                        setAlert(true);
                        setType('error');
                        setMessage('Something went wrong');
                    }
                    setLoader(false);
                });


        }

    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;
    const handleCopy = () => {
        navigator.clipboard.writeText(url)
        setAlert(true);
        setType('info');
        setMessage('Coppied');
    }
    const reset = () => {
        localStorage.removeItem("bd_ref");
        setUrl('')
        window.location.reload()
    }
    return (
        <Box style={{ backgroundColor: '#2F3C97', minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden' }}>
            <Grid container >
                <Grid item xs={12} sm={12} md={4} ></Grid>
                <Grid item xs={12} sm={12} md={4} sx={{ textAlign: 'center' }}>
                    <div style={{ justifyContent: 'center', fontSize: '50px', fontFamily: 'BarlowLight' }}>
                        {/* <span style={{color:'#2F3C97', fontWeight:600}}>CashKaro</span><span style={{color:'#FFFFFF'}}>Telegram</span> */}
                        <img src="https://asset20.ckassets.com/wp/wp-content/uploads/sites/37/2020/04/5e8dbdd7383b9.png" alt="" />
                    </div>
                    <Card sx={{ margin: '10px' }}>
                        <CardContent>
                            {isLoading ? <Loader show={isLoading} /> : <FormikProvider value={formik} >
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '20px', border: '1px solid #1B191F', borderRadius: '5px' }}>
                                    <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Convert Link</Typography>
                                    <Grid container spacing={3} >
                                       
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                label="Link"
                                                {...getFieldProps('deal')}
                                                size="small"
                                                error={Boolean(touched.deal && errors.deal)}
                                                helperText={touched.deal && errors.deal}
                                            />
                                        </Grid>
                                       {!refer  && <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                label="Referral Link /Referral ID"
                                                {...getFieldProps('referral')}
                                                size="small"
                                                error={Boolean(touched.referral && errors.referral)}
                                                helperText={touched.referral && errors.referral}
                                            />
                                        </Grid>}
                                      {url &&  <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <OutlinedInput
                                                type='text'
                                                fullWidth
                                                value={url}
                                                endAdornment={
                                                    <InputAdornment position="end" >
                                                        <IconButton
                                                            onClick={handleCopy}
                                                            edge="end"
                                                           
                                                        >
                                                            <CopyIcon  sx={{color:'blue'}} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>}
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Button variant="contained" type="submit" sx={{ textTransform: 'capitalize', background: '#2196f3', width: '100%' }}>Convert</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Button variant="contained" type="button" onClick={() =>reset()} sx={{ textTransform: 'capitalize', background: '#d50000', width: '100%' }}>Reset</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </FormikProvider>}
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>
            <div style={{ color: '#FFFFFF', display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
                <p>Copyright © 2023 CashKaro. All Rights Reserved</p>
            </div>
            <Alert open={alert} type={type} setOpen={setAlert} message={message} />
        </Box>

    )
}