import * as React from "react";
import { useRoutes, Navigate } from 'react-router-dom';
import Dashboard from './layouts/index';
// import Footer from './layouts/dashboard/footer';
import Login from './pages/login';
import Home from './pages/home';
import Shortner from './pages/shortner';
import Error from './pages/error';
import Stores from './pages/stores/index';
import StoreCreate from './pages/stores/create';
import StoreEdit from './pages/stores/edit';
import Users from './pages/users/index';
import UserAdd from './pages/users/userAdd';
import Networks from './pages/networks/index';
import Deals from './pages/deals/index';
import Exits from './pages/exits/index';
// import Loader from './component/loader';
import Converter from './pages/converter';
import Logout from './components/logout';
export default function Router() {
  const aat = localStorage.getItem('bd_aat');
  const bundle = localStorage.getItem('bd_bundle');
  const userrole = localStorage.getItem("bdr");
  let isLoggedIn = false;
  if (aat && bundle) {
    isLoggedIn = true;
  }
  return useRoutes(isLoggedIn ? userrole == 'Admin' ? [
    {
      path: "/",
      element: <Dashboard />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/stores", element: <Stores /> },
        // { path: "/exits", element: <Exits /> },
        { path: "/stores/create", element: <StoreCreate /> },
        { path: "/stores/edit", element: <StoreEdit /> },
        { path: "/users", element: <Users /> },
        { path: "/admin-networks", element: <Networks /> },
        { path: "/deals", element: <Deals /> },
        { path: '*', element: <Navigate to="/" replace /> },
      ]
    },
    { path: "/logout", element: <Logout /> }] :
    [
      { path: '/', element: < UserAdd /> },
      { path: "/logout", element: <Logout /> },
      { path: '*', element: <Navigate to="/" replace /> },
    ] : [
    {
      path: '/login',
      element: < Login />,
      children: [
        { path: '/login', element: < Login /> },
      ]
    },
    { path: '/:id', element: < Shortner /> },
    { path: '/', element: < Error /> },
    { path: '/converter', element: < Converter /> },
  ],


  );
}