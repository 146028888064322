import React, { useRef } from 'react';
import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import networkSchema from '../../validations/networkSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { Grid } from '@mui/material';
import { TextField, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';


export default function Edit(props) {
    const dialogBox = useRef()
    const { openPopup, setOpenPopup } = props;
    const [apiErrors, setApiErrors] = useState([]);
    const bundle = localStorage.getItem("bd_aat");
    const login_id = localStorage.getItem("bd_bki");
    // const login_type = localStorage.getItem("bka");
    const formik = useFormik({
        initialValues: {
            id: props?.record?.id ? props.record.id : '',
            name: props?.record?.name ? props.record.name : '',
            domain_name: props?.record?.domain_name ? props.record.domain_name : '',
            deeplink_key: props?.record?.deeplink_key ? props.record.deeplink_key : '',
            exit_id_1: props?.record?.exit_id_1 ? props.record.exit_id_1 : '',
            exit_id_2: props?.record?.exit_id_2 ? props.record.exit_id_2 : '',
        },
        enableReinitialize: true,
        validationSchema: networkSchema,

        onSubmit: (initialValues, { resetForm }) => {
            console.log(initialValues);
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bundle}`
                },
                body: JSON.stringify(initialValues)
            };

            fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/networks`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if ('error' in data) {
                        setApiErrors(data.errors);
                    } else if('success' in data) {
                        setOpenPopup(false);
                        setApiErrors([])
                        props.setAlert(true);
                        props.refreshTable();
                        props.setType('success');
                        props.setMessage(data.message);
                    } else {
                        setOpenPopup(false);
                        setApiErrors([])
                        props.setAlert(true);
                        props.setType('error');
                        props.setMessage('Something went wrong');
                    }
            });
            // navigate('/bank-info', { state: { path: 'login' } });

        }
    });
    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
 
    const {  errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Dialog
            ref={dialogBox}
            open={openPopup}
            onClose={() => { setOpenPopup(false) }}
            maxWidth="md"
           
        >

            <FormikProvider value={formik}  >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: '10px' }}>
                    <DialogContent >
                        <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: '10px' }}>Edit Networks</Typography>
                        <Grid container spacing={3}>
                            {Object.keys(apiErrors).length !== 0 && <Grid item xs={12} sm={12} md={12}>
                                <Box p={3} sx={{ background: '#DB0011', borderRadius: '8px', color:'#FFFFFF' }}>
                                    {
                                        Object.keys(apiErrors).map((item, i) => (
                                            <Typography key={i}>{apiErrors[item]}</Typography>
                                        ))
                                    }
                                </Box>
                            </Grid>}
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Name"
                                    {...getFieldProps('name')}
                                    size="small"
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Domain Name"
                                    {...getFieldProps('domain_name')}
                                    size="small"
                                    error={Boolean(touched.domain_name && errors.domain_name)}
                                    helperText={touched.domain_name && errors.domain_name}
                                />
                            </Grid>
                     
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Deeplink Identifier"
                                    {...getFieldProps('deeplink_key')}
                                    size="small"
                                    error={Boolean(touched.deeplink_key && errors.deeplink_key)}
                                    helperText={touched.deeplink_key && errors.deeplink_key}
                                />
                            </Grid>

                            
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Exit Id 1"
                                    {...getFieldProps('exit_id_1')}
                                    size="small"
                                    error={Boolean(touched.exit_id_1 && errors.exit_id_1)}
                                    helperText={touched.exit_id_1 && errors.exit_id_1}
                                />
                            </Grid>
                             <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Enter Exit Id 2"
                                    {...getFieldProps('exit_id_2')}
                                    size="small"
                                    error={Boolean(touched.exit_id_2 && errors.exit_id_2)}
                                    helperText={touched.exit_id_2 && errors.exit_id_2}
                                />
                            </Grid>
 
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="button"  color="primary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }} onClick={() => setOpenPopup(!openPopup) }>Cancel</Button>
                        <Button variant="contained" type="submit"  color="secondary" sx={{ float: 'right', textTransform: 'capitalize', marginRight: '10px' }}>Update</Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}