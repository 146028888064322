import * as React from 'react';
import ShortnerSchema from '../validations/shortner';
import { useFormik, Form, FormikProvider } from 'formik';
import { useState, useEffect, } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Alert from './../components/alert'
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TextField, Typography, Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Loader from '../components/loader';
import ConnecterImg from '../images/connecter.png';
import LoaderImg from '../components/loader5.svg';
import { textTransform } from '@mui/system';

export default function Shortner() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = useState(false);
    const [type, setType] = useState('');
    const [short_url, setShortUrl] = useState(null);
    const user_id = localStorage.getItem("ctui") ? localStorage.getItem("ctui") : '';
    const [isLoading, setLoader] = useState(true);
    const [isSuccess, setSuccess] = useState(false);
    const [isWrongMobile, setWrongMobile] = useState(false);
    const [formLoading, setFormLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [counter, setCounter] = useState(5);
    const [user_name, setUserName] = useState('');
    const [user_mobile, setUserMobile] = useState('');

    const CssTextField = styled(TextField)({
        textAlign: 'center',
        '& label.Mui-focused': {
            color: '#707070'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#707070',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#707070',
            },
            '& label.Mui-focused': {
                color: 'white',
                borderColor: "white"
            },
            '&:hover fieldset': {
                borderColor: '#707070',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#707070',
            },
        },
    });
    const outlineColor = {
        "& > fieldset": { borderColor: "#707070" },
        '& label.Mui-focused': {
            color: 'white',
            borderColor: "white"
        },
        '&:hover fieldset': {
            borderColor: '#707070',
        },
    }

    const callApi = (short_url) => {
        setLoader(true)
        let initialValues = {
            url: short_url,
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(initialValues),
        };

        fetch(`${process.env.REACT_APP_SITE_URL}/api/link/redirect-request`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data?.success) {
                    window.location.href = data.data.new_link;
                } else {
                    navigate('/', { replace: true });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }
    useEffect(() => {
        let path_name = window.location.pathname;
        console.log(path_name)
        if (path_name) {
            let url = path_name.replace('/', '');
            setShortUrl(url)
            callApi(url)
        } else {
            navigate('/', { replace: true });
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const formik = useFormik({
        initialValues: {
            mobile: '',
            url: '',
            user_id: '',
        },
        validationSchema: ShortnerSchema,
        onSubmit: (initialValues, {setErrors}) => {
            setFormLoader(true)
            initialValues.url = short_url
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(initialValues),
            };
           
            fetch(`${process.env.REACT_APP_SITE_URL}/api/link/redirect-request`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setFormLoader(false)
                    setUserMobile(initialValues.mobile)
                    if(data?.success){
                        setUserName(data.data.user_name)
                        setSuccess(true)
                        localStorage.setItem('ctui', data.data.user_id)
                      
                    } else {

                        
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

        }
    });


    return (
        <>
            <Box style={{ backgroundColor: '#FFFFF', minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden', paddingTop:'15%' }}>
                <Loader show={isLoading} />
            </Box>
            </>

    );
}
