import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Grid, Stack, Box } from '@mui/material';
import FilterPopup from './filter';
import Moment from 'moment';
import * as XLSX from 'xlsx/xlsx.mjs';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#07090A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export default function Index() {
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [openView, setOpenView] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);
  const [downloadExport, setDownloadExport] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [filters, setFilters] = useState({
    store_name: '',
    user_name: '',
    short_url: '',
    ckaro_url: '',
    ck_store_id: '',
    start_at: '',
    end_at: '',
  });
  const [onFilter, setOnFilters] = useState(false);
  const [page, setPage] = useState(0);
  const [size] = useState(15);
  const [data_id, setDataID] = useState(null);
  const [record, setRecord] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [totalStores, setTotalStores] = useState(0);
  // const [setAccessToken] = useState();
  // const [isLoading, setLoading] = useState(false);
  const login_id = localStorage.getItem("bd_bki");;
  const bundle = localStorage.getItem("bd_aat");


  const callApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/exits?store_name=${filters.store_name}&user_name=${filters.user_name}&short_url=${filters.short_url}&ckaro_url=${filters.ckaro_url}&ck_store_id=${filters.ck_store_id}&start_at=${filters.start_at}&end_at=${filters.end_at}&page=${page}&size=${size}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if ('error' in data) {
          setLoader(false)
          setAlert(true);
          setType('error');
          setMessage(data.message);
        } else {
          if (data?.data.dataItems.length !== undefined) {
            setData(data?.data.dataItems);
            setDownloadExport(data?.download_url)
            setTotalStores(data?.data.totalItems);
            setTotalItems(data?.data.totalItems % size ? (Math.floor(data?.data.totalItems / size) + 1) : Math.floor(data?.data.totalItems / size));
            setLoader(false);
          } else {
            setLoader(false)
            setAlert(true);
            setType('error');
            setMessage(data.message);
          }
        }
      });
  }
  useEffect(() => {
    callApi();
  }, [page]);// eslint-disable-line react-hooks/exhaustive-deps

  const openViewPopup = (e, id) => {
    setDataID(id);
    setRecord(data.find(o => o.id === id));
    setOpenView(!openView);
  };
  // const openDeletePopup = (e, id) => {
  //   setDataID(id);
  //   setOpenDelete(!openDelete);
  // };
  // console.log(filters);
  if (onFilter === true) {
    callApi();
    setOnFilters(false);
  }

  const handlePagination = (e, p) => {
    setPage(p - 1);
  }

  const handleOpen = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };
  const setOccaptionText = (occupation_reason) => {
    if (occupation_reason == 'salaried_employee') {
      return 'Salaried Employee'
    } else if (occupation_reason == 'self_employed_business') {
      return 'Salaried Employee Business'
    } else if (occupation_reason == 'self_employed_professionals') {
      return 'Salaried Employee Professionals'
    } else if (occupation_reason == 'others') {
      return 'Others'
    } else {
      return ''
    }
  };
  const handleExportDownload = () => {
    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = downloadExport;
    link.download = 'exits.csv'; // Specify the desired file name
    link.click();
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };

    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/auth/delete_export_url`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const exportApi = () => {
    setLoader(true);
    if (bundle != null) {
      // setAccessToken(bundle);
    } else {
      setAlert(true);
      setType('error');
      setMessage('No Token Found');
      navigate('/login', { replace: true });
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${bundle}`
      },
    };
    var workbook = XLSX.utils.book_new();
    fetch(`${process.env.REACT_APP_SITE_URL}/api/admin/exits/exports?exitid=${filters.exit_id}&product_name=${filters.product_name}&mobile=${filters.mobile}&vendor=${filters.vendor}&status=${filters.status}&start_at=${filters.start_at}&end_at=${filters.end_at}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if ('success' in data) {
          console.log(data)
      
            setAlert(true)
            setType('success')
            setMessage('Please refresh untill you see a download button')
            setLoader(false);
        } else {


        }
      });
  }
  return (
    <>
      <Accordion sx={{ border: '1px solid gray' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Filter </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterPopup setFilters={setFilters} onFilter={setOnFilters} />
        </AccordionDetails>
      </Accordion>
      <Stack direction="row" justifyContent="space-between" >
        <Typography variant="h5" sx={{ paddingY: '10px' }}>Exits</Typography>
        <Stack direction="row">
          {downloadExport && <Button variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: '#1b5e20', marginY: '10px', marginRight:'10px' }} onClick={handleExportDownload}> Download File </Button>}
          <LoadingButton variant="contained" startIcon={<AddIcon />} sx={{ backgroundColor: '#1b5e20', marginY: '10px' }} onClick={exportApi}> Export </LoadingButton>
        </Stack>
      </Stack>
      <p style={{color:'#DB0011', fontSize:'12px', marginY:'0px'}}>Note: After clicking on the Export button, it may take some time for the Download button to appear. Please refresh the page to ensure it appears.</p>
      {isLoading === true ? <Loader show={isLoading} /> :
        <>
          <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table" size="small" >
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Ckaro Url</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Short Url/Exit ID</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>User Name</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Referral</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Store Name</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>CK Store ID</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Sub ID 1</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'center', fontSize: '13px', padding: '4px' }}>Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? data.map((item) => (
                  <React.Fragment key={item.id}>
                    <TableRow >
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.shortners?.ckaro_url}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.shortners?.short_url}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.users?.name }</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.users?.referral }</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.stores?.name}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.ck_store_id}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{item?.sub_id_1}</TableCell>
                      <TableCell sx={{ textAlign: 'center', margin: '0px', fontSize: '13px', paddingY: '0px' }}>{new Moment(new Date(item.createdAt)).format('DD-MM-yyyy HH:MM:ss')}</TableCell>
                    </TableRow>
                  
                  </React.Fragment>
                )) : <TableRow>
                  <TableCell component="th" scope="item" sx={{ paddingY: '0px' }} colSpan={8}>No Record Found</TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {data.length > 0 ? <Pagination count={totalItems} page={page + 1} color="error" sx={{ paddingY: '20px' }} onChange={(e, page) => handlePagination(e, page)} /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}><Typography sx={{ float: 'right', paddingTop: '20px', paddingRight: '10px', fontWeight: '600' }}>Total Products: {totalStores}</Typography></Grid>
          </Grid> </>}
      <Alert open={alert} type={type} message={message} setOpen={setAlert} />

    </>
  );
}
