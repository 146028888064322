import React from 'react';
import * as Icon from '@mui/icons-material';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <Icon.Home  />
  },
  
  {
    title: 'Stores',
    path: '/stores',
    icon: <Icon.AddBusiness />
  },
  {
    title: 'Users',
    path: '/users',
    icon: <Icon.Groups />
  },
  // {
  //   title: 'Exits',
  //   path: '/exits',
  //   icon: <Icon.ExitToApp />
  // },
  {
    title: 'Deals',
    path: '/deals',
    icon: <Icon.Percent />
  },

  
];
